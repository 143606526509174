<template>
	<v-card elevation="10" align="start" height="365">
		<v-card-text class="full-height-wr">
			<div
				align="center"
				class="subtitle-1 font-weight-bold wr_indigo_2--text text-uppercase text-truncate"
			>
				{{ $t("app.your_current_task") }}
			</div>
			<div align="center" class="text-truncate">
				{{ $t("app.where_to_focus_today") }}
			</div>

			<div v-if="!userPlan.length" class="mt-15 pt-10" align="center">
				<v-img width="60" :src="require('@/assets/images/clock.png')"></v-img>
				<div class="mt-7 subtitle-1 wr_indigo_2--text">
					{{ $t("app.research_journey_not_started") }}
				</div>
			</div>

			<v-card-text v-else>
				<div class="mt-5 text-h6 info--text font-weight-bold">
					{{
						$t(
							`app.${getModuleName(
								userProfile.available_steps[0].plan_step.module.name[1],
							)}.title`,
						)
					}}
				</div>

				<div class="subtitle-1 font-weight-bold wr_indigo_2--text">
					<v-icon :left="!$vuetify.rtl" :right="$vuetify.rtl" small color="info"
						>$vuetify.icons.values.start_recording</v-icon
					>{{ userProfile.available_steps[0].plan_step.name }}
				</div>

				<div v-if="currentStage" class="subtitle-1 mt-5">
					<div class="font-weight-bold wr_indigo_2--text">
						{{ $t("app.finish_date") }}-
					</div>

					{{ moment(currentStage.plan_end_date).format("ll") }},
					{{ moment(currentStage.plan_end_date).fromNow() }}

					<div
						v-if="status"
						:class="['mt-5 title font-weight-bold', `${status.color}--text`]"
					>
						<span class="black--text">{{ $t("app.started") }}-&ensp;</span>
						<v-icon :color="status.color" size="24">{{
							$vuetify.icons.values[status.icon]
						}}</v-icon>

						{{ $t(`app.${status.name}`) | capitalize }}
					</div>
				</div>
			</v-card-text>

			<v-footer v-if="userPlan.length" absolute bottom color="white">
				<AppButton
					label="app.go_to_current_task"
					color="greenAccent2"
					:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
					:block="true"
					:prop_class="['mb-1', 'font-weight-bold']"
					@click="redirectToRelevantRoute()"
				></AppButton>
			</v-footer>
		</v-card-text>
	</v-card>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { accessManagementMixin } from "@/mixins/accessManagementMixin";

export default {
	name: "DashboardCurrentTask",

	mixins: [accessManagementMixin],

	data() {
		return {
			moment,
		};
	},

	computed: {
		...mapState({
			userPlan: (state) => state.planAndManage.user_plan,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
			getAvailableStepData: "planAndManage/getAvailableStepData",
		}),

		currentStage() {
			return this.getAvailableStepData(
				this.userProfile.available_steps[0].plan_step.id,
			);
		},

		status() {
			if (!this.currentStage) return;

			let plan_start = this.currentStage.plan_start_date;
			let actual_start = this.currentStage.actual_start_date;

			if (!plan_start || !actual_start) return null;

			plan_start = moment(plan_start).format("YYYY-MM-DD");
			actual_start = moment(actual_start).format("YYYY-MM-DD");

			if (actual_start > plan_start) {
				return {
					name: this.$defines.DELAYED_STATUS_STRING,
					color: "error",
					icon: "alert",
				};
			}
			if (actual_start < plan_start) {
				return {
					name: this.$defines.EARLIER_STATUS_STRING,
					color: "info",
					icon: "check_circle",
				};
			}
			return {
				name: this.$defines.TIMELY_STATUS_STRING,
				color: "success",
				icon: "clock",
			};
		},
	},

	methods: {
		getModuleName(module_value) {
			try {
				return this.$store.getters["research/getModuleName"](module_value);
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style></style>
