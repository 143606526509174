<template>
	<v-container fluid>
		<v-card flat class="wr_grey_1">
			<v-card-text>
				<v-row no-gutters>
					<!-- Profile info -->
					<v-col>
						<Introduction></Introduction>
					</v-col>
					<!-- Project info -->
					<v-col>
						<div
							align="center"
							class="greyDarken4--text text-h4 font-weight-bold"
						>
							{{
								userProfile.group
									? userProfile.group.project_name
									: $t("app.research_project")
							}}
							<div class="subtitle-1 mt-1">
								{{ $t("app.your_learning_activity") }}
							</div>
						</div>
						<v-divider class="mt-3 divider-wr"></v-divider>
					</v-col>
					<!-- Sign out -->
					<v-col>
						<TheLogout></TheLogout>
					</v-col>
				</v-row>
			</v-card-text>

			<!-- messages and meetings -->
			<v-card-text class="mt-5">
				<v-row>
					<v-col xs="12" sm="6" md="4">
						<div class="ms-2">
							<CurrentTask></CurrentTask>
						</div>
					</v-col>
					<v-col xs="12" sm="6" md="4">
						<div>
							<Notifications></Notifications>
						</div>
					</v-col>
					<v-col xs="12" sm="6" md="4" class="mx-auto">
						<div class="me-2">
							<Meetings></Meetings>
						</div>
					</v-col>
				</v-row>

				<!-- Progress -->
				<Progress></Progress>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Notifications from "@/components/shared/dashboard/Notifications.vue";
import Meetings from "@/components/shared/dashboard/Meetings.vue";
import CurrentTask from "@/components/shared/dashboard/CurrentTask.vue";
import TheLogout from "@/components/layout/TheLogout.vue";
import Progress from "@/components/shared/dashboard/Progress.vue";
import Introduction from "@/components/shared/dashboard/Introduction.vue";

export default {
	name: "StudentDashboard",

	components: {
		Notifications,
		Meetings,
		CurrentTask,
		TheLogout,
		Progress,
		Introduction,
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
		}),
	},
};
</script>

<style scoped>
.divider-wr {
	border: 2px solid var(--v-greenAccent2-base);
}
</style>
