<template>
	<v-card elevation="10" class="mx-2 mt-5">
		<v-card-text class="full-height-wr">
			<div
				align="center"
				class="subtitle-1 font-weight-bold greyDarken4--text text-uppercase"
			>
				{{ $t("app.your_learning_progress") }}
			</div>
			<div align="center">
				{{ $t("app.progress_desc") }}
			</div>

			<div v-if="!userPlan || !userPlan.length" class="mt-15" align="center">
				<AppButton
					label="app.forward_journey"
					:large="true"
					:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
					:class="['font-weight-bold']"
					@click="redirectToRelevantRoute()"
				></AppButton>
			</div>

			<div class="mt-5">
				<AppModuleProgress
					v-if="latestAvailableStage"
					v-model="latestAvailableStage.value"
					:events="moduleEvents"
				></AppModuleProgress>
			</div>

			<div>
				<AppModulesProgress
					v-if="latestAvailableStage"
					v-model="latestAvailableStage.module_id"
					:events="modulesEvents"
				></AppModulesProgress>
			</div>

			<div>
				<PlanningComparison
					v-if="userPlan.length"
					:show_heading="false"
					:prop_module="latestAvailableStage.module_id"
				></PlanningComparison>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import AppModuleProgress from "@/components/ui/AppModuleProgress.vue";
import AppModulesProgress from "@/components/ui/AppModulesProgress.vue";
import PlanningComparison from "@/components/research/modules/_common/PlanningComparison.vue";
import { accessManagementMixin } from "@/mixins/accessManagementMixin";

export default {
	name: "DashboardProgress",

	mixins: [accessManagementMixin],

	components: {
		AppModuleProgress,
		AppModulesProgress,
		PlanningComparison,
	},

	computed: {
		...mapState({
			userPlan: (state) => state.planAndManage.user_plan,
		}),

		moduleEvents: function() {
			return this.$store.getters["planAndManage/getModuleStagePlan"](
				"M" + this.latestAvailableStage.module_id,
			);
		},

		modulesEvents() {
			return this.$store.getters["planAndManage/getModulePlan"]();
		},
	},
};
</script>

<style></style>
