<template>
	<Dashboard></Dashboard>
</template>

<script>
import Dashboard from "@/components/shared/dashboard/Index.vue";
export default {
	name: "UserDashboardPage",

	components: {
		Dashboard,
	},

	created() {
		this.getUserPlan();
	},

	methods: {
		async getUserPlan() {
			try {
				this.$loader.start();

				await this.$store.dispatch("planAndManage/getUserPlan");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.is_fetching = false;
				this.$loader.stop();
			}
		},
	},
};
</script>
